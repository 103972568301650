<template>
  <div class="print_table" ref="print_table">
    <div class="print">
      <div class="big_title">
        <span>{{ tabName }}</span>
        <svg
          class="icon"
          aria-hidden="true"
          @click="printBtn"
          style="width: 20px; cursor: pointer"
        >
          <use href="#icon-dayin_d"></use>
        </svg>
      </div>
      <div class="title">
        <span>{{ viewName }}</span>
        <div style="width: 150px">
          <span>{{ $t('label.view.sortview.number') }}</span>
          <el-select
            v-model="page"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
            @change="handleSizeChange"
            style="width: 90px; height: 30px"
          >
            <el-option
              v-for="item in pageOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div ref="printTest" class="printTest" id="printTest">
        <!-- 表格 -->
        <table-panel
          class="text-center"
          ref="tablePanel"
          :border="true"
          :pagination="false"
          :show-index="false"
          :showLock="showLock"
          :checked="false"
          :prefix="prefix"
          :obj-id="objId"
          :object-api="objectApi"
          :view-id="viewId"
          :view-modify="ismodifyFalg"
          :pageNum="pageNum"
          :table-attr="tableAttr"
          :page-obj="pageObj"
          :table-button="tableBtnList"
          :noDataTip="noDataTip"
          :style="{ width: '100%', height: '100%' }"
        ></table-panel>
      </div>
      <footer>
        <p class="foot">
          {{ $t('label.copyright') }}
        </p>
      </footer>
    </div>
  </div>
</template>
<script>
import { TablePanel } from '@/components/index'
import * as CommonObjApi from '../../../api'
import waterMark from '@/utils/waterMark/callMark.js'

export default {
  components: { TablePanel },
  name: 'PrintTable',
  data() {
    return {
      campaignId: this.$route.params.campaignId,
      channel: this.$route.params.channel,
      printDialog: this.printFlag,
      noDataTip: false,
      namaLableList: [],
      sum: '',
      pageOption: [
        {
          value: '50',
          label: '50',
        },
        {
          value: '100',
          label: '100',
        },
        {
          value: '150',
          label: '150',
        },
        {
          value: '250',
          label: '250',
        },
        {
          value: '500',
          label: '500',
        },
        {
          value: '1000',
          label: '1000',
        },
      ],
      page: '50',
      tableBtnList: {
        title: '',
        buttonList: [],
      },
      pageObj: {
        dataList: [],
      },
      ismodifyFalg: 'false',
      tableAttr: [
        {
          id: 'null',
          colwidth: '255',
          schemefieldName: 'name',
          nameLabel: '活动成员',
        },
        {
          id: 'null',
          colwidth: '255',
          schemefieldName: 'phone',
          nameLabel: '电话',
        },
        {
          id: 'null',
          colwidth: '255',
          schemefieldName: 'email',
          nameLabel: '邮箱',
        },
        {
          id: 'null',
          schemefieldName: 'status',
          nameLabel: '状态',
        },
      ],
      showLock: false,
      pageSize: 50,
      pageNum: 1,
      totalSize: 0,
      tabName: this.$i18n.t('label.marketactivitymember'),
      viewName: this.$i18n.t('label.recentview.news'),
      // prefix: this.$route.params.prefix,
      prefix: '006',
      // objId: this.$route.params.objId,
      // objectApi: this.$route.params.objectApi,
      // viewId: this.$route.params.viewId,
      objId: '',
      objectApi: '/channel/queryMemberByChannel',
      viewId: '99',
      selectLen: 50, //下拉菜单改变时的条数
      scroll: '',
      diogHeight: 0, //水印默认高度
    }
  },
  created() {
    document.title = this.$setTitle(`${this.tabName}——${this.viewName}`, false)
  },
  mounted() {
    this.initListFun('50', '1')
    // 执行的方法被注释，监听无效
    // window.addEventListener('scroll', this.handleScroll)
  },

  //销毁,否则跳到别的路由还是会出现
  destroyed() {
    // window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    //  handleScroll(){
    //     this.scroll = document.documentElement.scrollTop||document.body.scrollTop
    //     var height=this.$refs.print_table.offsetHeight+150
    //       
    //     if(this.scroll<=height){
    //       this.diogHeight=height
    //       
    //     }else{
    //       this.diogHeight=this.scroll
    //     }
    //     // 打印页面加水印
    //     if (
    //       localStorage.getItem("openWater") == "false" &&
    //       document.getElementsByClassName("printTest")[0]
    //     ) {
    //       
    //       waterMark(
    //         this.$refs.printTest,
    //         document.getElementsByClassName("printTest")[0].clientWidth + "px",
    //         this.diogHeight + "px",
    //         "999999",
    //         "detail"
    //       );
    //     }
    // },
    printBtn() {
      // let subOutPrintButton=document.getElementsByClassName("print");
      // var newContent=[];
      // for(var i=0;i<subOutPrintButton.length;i++){
      //  var obj=subOutPrintButton[i].innerHTML;
      //  newContent.push(obj);
      // }
      // document.body.innerHTML = newContent;
      // document.getElementsByTagName('body')[0].style.color="#000"
      // document.getElementsByTagName('body')[0].style.zoom=0.85;
      // window.print();
      // window.location.reload();
      window.print()
    },
    handleEdit() {},
    handleDelete() {},
    initListFun(n) {
      let data = {
        channel: this.channel,
        campaignId: this.campaignId,
      }
      CommonObjApi.getMarketingActivities(data).then((res) => {
        if (res.data !== null) {
          this.pageObj.dataList = res.data.slice(0, n)
          this.selectLen = res.data.lengths
          this.pageNum++
          // 打印页面加水印
          this.diogHeight =
            Number(document.getElementsByClassName('foot')[0].offsetTop) + 150
          if (
            localStorage.getItem('openWater') == 'true' &&
            document.getElementsByClassName('print_table')[0] &&
            this.$route.path.split('/')[1] === 'print-table'
          ) {
            waterMark(
              this.$refs.printTest,
              document.getElementsByClassName('print_table')[0].clientWidth +
                'px',
              this.diogHeight + 'px',
              '999999',
              'detail'
            )
          }
        }
      })
    },
    // 下拉菜单
    handleSizeChange(val) {
      if (val == 50) {
        if (this.selectLen < 50) {
          this.initListFun(this.selectLen, (this.pageNum = 1))
        } else {
          this.initListFun(val, (this.pageNum = 1))
        }
      } else if (val == 100) {
        if (this.selectLen < 100) {
          this.initListFun(this.selectLen, (this.pageNum = 1))
        } else {
          this.initListFun(val, (this.pageNum = 1))
        }
      } else if (val == 150) {
        if (this.selectLen < 150) {
          this.initListFun(this.selectLen, (this.pageNum = 1))
        } else {
          this.initListFun(val, (this.pageNum = 1))
        }
      } else if (val == 250) {
        if (this.selectLen < 250) {
          this.initListFun(this.selectLen, (this.pageNum = 1))
        } else {
          this.initListFun(val, (this.pageNum = 1))
        }
      } else if (val == 500) {
        if (this.selectLen < 500) {
          this.initListFun(this.selectLen, (this.pageNum = 1))
        } else {
          this.initListFun(val, (this.pageNum = 1))
        }
      } else if (val == 1000) {
        if (this.selectLen < 1000) {
          this.initListFun(this.selectLen, (this.pageNum = 1))
        } else {
          this.initListFun(val, (this.pageNum = 1))
        }
      } else {
        this.initListFun(val, (this.pageNum = 1))
      }
    },
  }
}
</script>
<style scoped rel="stylesheet/scss" lang="scss">
/**/
@media print {
  .icon {
    display: inline-block;
  }
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  img {
    display: none;
  }

  .printTest {
    ::v-deep .is-leaf {
      // width: 100%;
      border-top: 1px solid #ebeef5;
      span {
        color: #000;
      }
    }
  }
  #pdfDom {
    -webkit-print-color-adjust: exact;
  }
  ::v-deep .el-table .cell,
  ::v-deep .tableHeadField {
    //width: 100%;
    white-space: normal;
    padding-right: 0;
    cursor: auto;
    pointer-events: none;
  }
  ::v-deep .tableHeadField span {
    font-size: 12px;
    color: #080707;
  }
  ::v-deep .allow-click,
  .cannotEdit {
    color: #080707;
  }
  ::v-deep tr td a,
  ::v-deep tr td span,
  ::v-deep .el-tooltip div {
    font-size: 12px;
  }
  ::v-deep .el-table tbody tr:hover > td,
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }
  ::v-deep .el-table td {
    padding: 0;
  }
  ::v-deep .editIconImg {
    display: none;
  }
}

.big_title div {
  span {
    margin-right: 10px;
  }
}
.big_title {
  border-bottom: 1px solid #dddbda;
  height: 48px;
  span {
    display: inline-block;
    line-height: 48px;
  }
}
.big_title,
.title {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  span {
    font-weight: bolder;
  }
}
.title {
  margin: 20px 0;
  span {
    margin-right: 10px;
    font-size: 12px;
  }
  ::v-deep input {
    height: 30px;
  }
  ::v-deep .el-input__suffix {
    top: 5px;
  }
}
.print_table {
  //padding: 20px 5px;
  margin: 10px;
  box-sizing:border-box img {
    position: absolute;
    top: 20px;
    right: 50px;
    cursor: pointer;
  }
  ::v-deep .el-table .cell,
  ::v-deep .tableHeadField {
    white-space: normal;
    padding-right: 0;
    cursor: auto;
    pointer-events: none;
  }
  ::v-deep .tableHeadField span {
    font-size: 12px;
    color: #333;
  }
  ::v-deep .allow-click,
  .cannotEdit {
    color: #080707;
  }
  ::v-deep tr td a,
  ::v-deep tr td span,
  ::v-deep .el-tooltip div {
    font-size: 12px;
  }
  ::v-deep .el-table tbody tr:hover > td,
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }
  ::v-deep .el-table td {
    padding: 8px !important;
  }
  ::v-deep .editIconImg {
    display: none;
  }
}
footer {
  margin-top: 20px;
  p {
    width: 100%;
    text-align: center;
  }
}
</style>
